<template>
  <div>
    <el-form style="width: 100%">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请输入关键字">
            <el-input placeholder="请输入关键字" v-model="searchData.search" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入操作人名称">
            <el-input
              placeholder="请输入操作人名称"
              v-model="searchData.userName"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请选择操作时间">
            <el-date-picker
              v-model="searchData.createTime"
              type="date"
              placeholder="请选择操作时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>

        <el-col :span="2">
          <el-button type="primary" @click="onLogList"> 搜索 </el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-table
      :data="logData"
      border
      style="width: 100%"
      height="80vh"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column show-overflow-tooltip prop="type" label="操作类型" />
      <el-table-column show-overflow-tooltip prop="detail" label="操作详情" />
      <el-table-column show-overflow-tooltip prop="detail" label="操作详情" />
      <el-table-column show-overflow-tooltip prop="method" label="操作方法" />
      <el-table-column show-overflow-tooltip prop="uri" label="URL" />
      <el-table-column show-overflow-tooltip prop="ip" label="IP" />
      <el-table-column show-overflow-tooltip prop="req" label="请求参数" />
      <el-table-column show-overflow-tooltip prop="resp" label="相应数据" />
      <el-table-column show-overflow-tooltip prop="user_name" label="操作人" />
      <el-table-column
        show-overflow-tooltip
        prop="create_time"
        label="操作时间"
      />
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[100, 200, 500, 1000]"
          :page-size="searchData.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchData.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { logListAPI } from '@/api/operate-log'
const searchData = ref({
  page: 1, // 当前页
  total: 0, // 总数
  limit: 100, //  每页几条数据
  search: '',
  userName: '',
  createTime: ''
})

const logData = ref([])

const onLogList = async () => {
  const { data, total } = await logListAPI(searchData.value)
  logData.value = data
  searchData.value.total = total
  console.log(data, total)
}
onLogList()

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  searchData.value.limit = val
  onLogList()
}

// 页码值
const handleCurrentChange = (val) => {
  searchData.value.page = val
  onLogList()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped></style>
