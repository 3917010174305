import request from '@/utils/request'

// 日志列表
export const logListAPI = (params) => {
  return request({
    url: '/logList',
    method: 'GET',
    params
  })
}
